body {
  margin: 0;
  font-family: 'Roboto', 'Arial', 'Helvetica Neue', 'sans-serif', 'Segoe UI', 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p:not(:empty) {
  margin: 0;
}

.RichEditor-align-left {
  text-align: left;
}

.RichEditor-align-center {
  text-align: center;
}

.RichEditor-align-right {
  text-align: right;
}

.RichEditor-align-left div {
  text-align: left;
}

.RichEditor-align-center div {
  text-align: center;
}

.RichEditor-align-right div {
  text-align: right;
}
